import React from 'react';
import SEO from '../components/SEO';

export default function FourOhFourPage() {
  return (
    <div>
      <SEO title="404" />
      Seite leider nicht gefunden 😢
    </div>
  );
}
